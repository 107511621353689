import styles from './action-vertical.module.scss';
import Link from 'next/link';

interface Props {
	button_title: any;
	color_code: any;
	link: any;
	target?: string;
}

const ActionVerticalButton = (props: Props) => {
	const { button_title, color_code, link } = props;
	return (
		<Link href={link || '/'} target={props.target || '_self'} className={styles.button_wrap} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
			<div style={{ color: `${color_code}`, textAlign: 'center' }}>{button_title}</div>
		</Link>
	);
};

export default ActionVerticalButton;
